import { atom, selector } from 'recoil'
import {
  LoginCommand,
  LoginStatus,
  Authenticate,
  ValidateToken,
  SendTokenEmail,
  isLegacyUsernameErrorType,
  isExpiredPasswordErrorType,
  isCompanyCodeNotFoundErrorType,
  FindUser,
  ValidateGoogleToken,
  ResetPassword
} from './Domain'

const loginCommandState = atom<LoginCommand | null>({
  key: 'loginCommand',
  default: null
})

const errorState = atom({
  key: 'error',
  default: ''
})

const successAlertState = atom({
  key: 'successAlert',
  default: ''
})

const hasSmsState = atom({
  key: 'hasSms',
  default: false
})

const returnUrlState = atom({
  key: 'returnUrl',
  default: ''
})

const loginStatusState = atom({
  key: 'loginStatus',
  default: LoginStatus.WAITING
})

const languageState = atom({
  key: 'language',
  default: 'en'
})

const isGoogleTfaEnabledState = atom({
  key: 'isGoogleTfaEnabled',
  default: false
})

const companyCodeState = atom({
  key: 'companyCode',
  default: ''
})

const isReauthState = atom({
  key: 'isReauth',
  default: false
})

const isTokenCommandState = selector({
  key: 'isTokenCommand',
  get: ({ get }) => {
    const loginCommand = get(loginCommandState)

    return (
      Authenticate.is(loginCommand) ||
      ValidateToken.is(loginCommand) ||
      SendTokenEmail.is(loginCommand)
    )
  }
})

const isLegacyUsernameErrorTypeState = selector({
  key: 'isLegacyUserNameErrorTypeState',
  get: ({ get }) => {
    const error = get(errorState)

    return Boolean(error) && isLegacyUsernameErrorType(error)
  }
})

const isExpiredPasswordErrorTypeState = selector({
  key: 'isExpiredPasswordErrorTypeState',
  get: ({ get }) => {
    const error = get(errorState)

    return Boolean(error) && isExpiredPasswordErrorType(error)
  }
})

const isCompanyCodeNotFoundErrorTypeState = selector({
  key: 'isCompanyCodeNotFoundErrorTypeState',
  get: ({ get }) => {
    const error = get(errorState)

    return Boolean(error) && isCompanyCodeNotFoundErrorType(error)
  }
})

const canResetPasswordState = selector({
  key: 'canResetPassword',
  get: ({ get }) => {
    const loginCommand = get(loginCommandState)

    return (
      FindUser.is(loginCommand) ||
      Authenticate.is(loginCommand) ||
      ValidateToken.is(loginCommand) ||
      ValidateGoogleToken.is(loginCommand) ||
      ResetPassword.is(loginCommand)
    )
  }
})

const isRequestingState = selector({
  key: 'isRequesting',
  get: ({ get }) => get(loginStatusState) === LoginStatus.REQUESTING
})

export const Atoms = {
  loginCommand: loginCommandState,
  error: errorState,
  successAlert: successAlertState,
  hasSms: hasSmsState,
  returnUrl: returnUrlState,
  loginStatus: loginStatusState,
  language: languageState,
  isGoogleTfaEnabled: isGoogleTfaEnabledState,
  companyCode: companyCodeState,
  isTokenCommand: isTokenCommandState,
  isLegacyUsernameErrorType: isLegacyUsernameErrorTypeState,
  isExpiredPasswordErrorType: isExpiredPasswordErrorTypeState,
  isCompanyCodeNotFoundErrorType: isCompanyCodeNotFoundErrorTypeState,
  canResetPassword: canResetPasswordState,
  isRequesting: isRequestingState,
  isReauth: isReauthState
}
