import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const TroubleLoggingIn: React.FC = () => {
  const { t } = useTranslation('login')

  return (
    <a
      href='https://central.toasttab.com/s/article/Toast-Payroll-Logging-In'
      target='_blank'
      rel='noopener noreferrer'
      className='forgotLink troubleLoggingIn'
      data-testid='trouble-logging-in'
    >
      {t('troubleLoggingIn')}
    </a>
  )
}
