import * as React from 'react'
import { useWindowProvider } from './window'

export type EnvironmentLabel = 'prod' | 'preprod' | 'qa' | 'dev' | 'local'

interface EnvironmentConfig {
  label: EnvironmentLabel
  isLocal: boolean
  isProduction: boolean
  isQA: boolean
  isDev: boolean
}

const environmentMap: Map<string, EnvironmentLabel> = new Map([
  ['preprod.eng.toasttab', 'preprod'],
  ['web.qastratex', 'qa'],
  ['web.devstratex', 'dev'],
  ['localhost', 'local'],
  ['toastteam', 'local'],
  ['http://', 'local']
])

export const getEnvConfigFromLabel = (
  label: EnvironmentLabel
): EnvironmentConfig => ({
  label,
  isProduction: label === 'prod',
  isQA: label === 'qa',
  isDev: label === 'dev',
  isLocal: label === 'local'
})

export const getEnv = (url: string): EnvironmentLabel => {
  const env = Array.from(environmentMap.entries()).reduce((total, entry) => {
    const [key, value] = entry
    if (url.indexOf(key) > -1) {
      return value
    } else {
      return total
    }
  }, 'prod')
  return env as EnvironmentLabel
}

export const EnvironmentContext = React.createContext<EnvironmentConfig>(
  null as unknown as EnvironmentConfig
)

export const EnvironmentProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const window = useWindowProvider()
  const label = getEnv(window.location.href)

  const environment = getEnvConfigFromLabel(label)

  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  )
}
