import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useGoBack } from '../Hooks'

export const BackButton: React.FC = () => {
  const { t } = useTranslation('login')
  const goBack = useGoBack()

  return (
    <button
      type='button'
      data-test-id='back'
      className='flex items-center justify-center backButton'
      onClick={() => goBack()}
    >
      {t('back')}
    </button>
  )
}
