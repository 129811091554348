import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { LoginScreen } from '../Domain'
import { useSetScreen } from '../Hooks'

export const ForgotPasswordButton: React.FC = () => {
  const { t } = useTranslation('login')
  const setScreen = useSetScreen()

  return (
    <div className='forgotLink'>
      <button
        type='button'
        onClick={() => setScreen(LoginScreen.FORGOT_PASSWORD)}
        className='hover:underline type-subhead text-left w-full'
      >
        {t('dontRememberYourPassword')}
      </button>
    </div>
  )
}
