import * as React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { Atoms } from '../atoms'

interface SuccessAlertProps {
  message?: string | null
}

export const SuccessAlert: React.FC<SuccessAlertProps> = ({ message }) => {
  const successAlert = useRecoilValue(Atoms.successAlert)
  const { t } = useTranslation('login')

  if (successAlert || message) {
    return (
      <Alert variant='success' className='w-full'>
        {t(successAlert || message || '')}
      </Alert>
    )
  } else {
    return null
  }
}
