import * as React from 'react'
import Carousel from '../carousel/Carousel'
import { LanguageSwitcher } from '../components/LanguageSwitcher'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CompanyCodeAndEmailForm } from '../components/CompanyCodeAndEmailForm'
import { PasswordForm } from '../components/PasswordForm'
import { TokenForm } from '../components/TokenForm'
import { GoogleAuthenticatorForm } from '../components/GoogleAuthenticatorForm'
import { ForgotPasswordForm } from '../components/ForgotPasswordForm'
import { ResetPasswordForm } from '../components/ResetPasswordForm'
import { LoginScreen } from '../Domain'
import { SecurityResetForm } from '../components/SecurityResetForm'

import { SimplifiedLoginRedirectGuard } from '../simplified-login-migration/SimplifiedLoginRedirectGuard'

export const LoginLayout: React.FC = () => {
  return (
    <SimplifiedLoginRedirectGuard newUrl='/sign-in'>
      <div className='flex flex-col-reverse md:flex-row'>
        <div className='z-0 lg:w-3/5'>
          <Carousel />
        </div>
        <div className='z-10 w-full light-grey-bg lg:w-2/5'>
          <div className='container flex flex-col flex-wrap items-center content-center justify-center h-screen'>
            <div className='flex flex-col items-center justify-center loginContainer'>
              <div>
                <LanguageSwitcher />
              </div>
              <div className='flex flex-col items-center justify-center w-full p-6 bg-white shadow ring-2'>
                <div className='logo'>
                  <img
                    src='https://cdn2.hubspot.net/hubfs/412971/toast-restaurant-pos.png'
                    className='w-full'
                    alt=''
                  />
                </div>
                <div className='w-full mb-26'>
                  <Router>
                    <Switch>
                      <Route exact path={LoginScreen.COMPANY_CODE_AND_EMAIL}>
                        <CompanyCodeAndEmailForm />
                      </Route>

                      <Route exact path={LoginScreen.SECURITY_UPDATE}>
                        <SecurityResetForm />
                      </Route>

                      <Route exact path={LoginScreen.PASSWORD}>
                        <PasswordForm />
                      </Route>

                      <Route exact path={LoginScreen.TOKEN}>
                        <TokenForm />
                      </Route>

                      <Route exact path={LoginScreen.GOOGLE_AUTHENTICATOR}>
                        <GoogleAuthenticatorForm />
                      </Route>

                      <Route exact path={LoginScreen.FORGOT_PASSWORD}>
                        <ForgotPasswordForm />
                      </Route>

                      <Route exact path={LoginScreen.RESET_PASSWORD}>
                        <ResetPasswordForm />
                      </Route>
                    </Switch>
                  </Router>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SimplifiedLoginRedirectGuard>
  )
}
