import * as React from 'react'
import { CoreConfig, ConfiguredWindow } from '@local/ec-app'
import { useWindowProvider } from './window'

export const ConfigContext = React.createContext<CoreConfig>(
  null as unknown as CoreConfig
)

const currentConfigResolver = (window: Window): CoreConfig => {
  return (window as ConfiguredWindow).Toast
}

export const ConfigProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const window = useWindowProvider()

  return (
    <ConfigContext.Provider
      value={{
        ...currentConfigResolver(window),
        refreshConfig: () => currentConfigResolver(window)
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
