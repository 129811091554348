import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { withEcApplication } from '@local/ec-app'
import { CompanyCodeLoginApp } from './App'
import { AppProviders } from './providers/AppProviders'
import { LoginScreen } from './Domain'

const LoginApp = () => {
  return (
    <Router>
      <AppProviders>
        <Switch>
          <Route
            path={[
              LoginScreen.COMPANY_CODE_AND_EMAIL,
              LoginScreen.SECURITY_UPDATE,
              LoginScreen.PASSWORD,
              LoginScreen.TOKEN,
              LoginScreen.GOOGLE_AUTHENTICATOR,
              LoginScreen.FORGOT_PASSWORD,
              LoginScreen.RESET_PASSWORD
            ]}
            component={CompanyCodeLoginApp}
          />
        </Switch>
      </AppProviders>
    </Router>
  )
}

export default withEcApplication(LoginApp, 'ec-login')
