import * as yup from 'yup'
import * as React from 'react'

import { FormContainer } from '../components/FormContainer'
import { Formik } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { ErrorView } from '../components/ErrorView'
import { ButtonContainer } from '../components/ButtonContainer'
import { BackButton } from '../components/BackButton'
import { NextButton } from '../components/NextButton'
import { useTranslation } from 'react-i18next'
import { useSetGoogleToken, useSwitchToEmailToken } from '../Hooks'

class GoogleAuthenticatorFormValues {
  private constructor(readonly token: string) {}

  static schema = yup
    .object({
      token: yup.string().required('google_token_required')
    })
    .defined()

  static of(obj: any): GoogleAuthenticatorFormValues {
    return GoogleAuthenticatorFormValues.schema.validateSync(obj, {
      abortEarly: false
    })
  }
}

export const GoogleAuthenticatorForm: React.FC = () => {
  const { t } = useTranslation('login')
  const setGoogleToken = useSetGoogleToken()
  const switchToEmailToken = useSwitchToEmailToken()

  return (
    <FormContainer
      title={t('twoStepVerification')}
      subtitle={t('googleTokenInstructions')}
    >
      <Formik<GoogleAuthenticatorFormValues>
        initialValues={{ token: '' }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={GoogleAuthenticatorFormValues.schema}
        onSubmit={(values: GoogleAuthenticatorFormValues) =>
          setGoogleToken(values.token)
        }
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className='mb-6'>
              <div className='inputName inputContainer'>
                {t('google_token')}
              </div>
              <TextInputField
                id='token'
                name='token'
                testId='google-authenticator-token'
                errorText={t(props.errors.token || '')}
                autoFocus={true}
              />
            </div>
            <div className='forgotLink'>
              <button
                type='button'
                onClick={() => switchToEmailToken()}
                className='hover:underline type-subhead text-left w-full'
              >
                {t('switchToEmailToken')}
              </button>
            </div>
            <ErrorView />
            <ButtonContainer>
              <BackButton />
              <NextButton />
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}
