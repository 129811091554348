import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useTranslation } from 'react-i18next'
import { Atoms } from '../atoms'
import { useNextButtonClickHandler } from '../Hooks'

export const NextButton: React.FC = () => {
  const { t } = useTranslation('login')
  const isRequesting = useRecoilValue(Atoms.isRequesting)
  const onNextClick = useNextButtonClickHandler()

  return (
    <button
      type='submit'
      data-test-id='next'
      disabled={isRequesting}
      className='flex items-center justify-center loginButton'
      onClick={() => onNextClick()}
    >
      {isRequesting ? <MerryGoRound /> : t('next')}
    </button>
  )
}
