import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import ApplicationRoot from './app/ApplicationRoot'
import { domElementGetter } from '@toasttab/ec-layout'
import './index.css'

/**
 * This is the only app that is truly full width.
 *
 * 'full-width' is a class that is provided by ec-spa.css in esx-web
 */
document.body.classList.add('full-width')

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-login',
  rootComponent: ApplicationRoot,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer']
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-login-spa'
