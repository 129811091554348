import * as React from 'react'
import { useI18n } from '@local/ec-app'
import { useConfig } from '@local/ec-app'
import { LoginLayout } from './components/LoginLayout'
import {
  useLoginNavigationReset,
  useQueryParamState,
  useLanguageSwitcher,
  useLoginCommandHandler
} from './Hooks'

export const CompanyCodeLoginApp = () => {
  const { auth0BaseUrl, auth0ClientId } = useConfig()

  const i18n = useI18n()

  i18n.addResourceBundle('en', 'login', {
    error: {
      unknown: 'Something went wrong',
      unauthorized: 'Wrong username or password',
      invalidToken: 'The token you entered is incorrect. Please try again',
      translationFailed: 'Failed to save translation settings',
      singleSignOnEnabled:
        'Password reset error. Company has single sign-on enabled. Please contact your manager.',
      companyCodeNotFound: 'Company code was not found',
      legacyUsernameProvided: 'Please sign in with your email address',
      customerAccessNotAllowed:
        'The company code you are trying to log in to is currently not active on Toast Payroll. If you are an employee, please reach out to your restaurant manager for next steps.',
      expiredPassword: ' required',
      expiredPasswordLink: 'Password reset'
    },
    success: {
      passwordReset:
        'We’ve sent you an email with reset instructions. If you don’t see it in your inbox within 2 hours, check your spam folder and try resetting your password again.',
      passwordSuccess: 'Password has been successfully reset.'
    },
    welcome: 'Welcome',
    twoStepVerification: 'Two Step Verification',
    enterToken: 'Please enter the login token we just sent to your email',
    logIn: 'Log in to Toast Payroll',
    forgotCompanyCode: "Don't remember your company code?",
    troubleLoggingIn: 'Trouble logging in?',
    forceResetPassword: 'Reset your password',
    forceResetPasswordInstructions:
      'Request a password reset, and we’ll send an email to help get you back on track.',
    dontRememberYourPassword: "Don't remember your password?",
    dontRememberYourPasswordInstructions:
      'We’ll send an email to help reset your password and get you back on track',
    forgotPassword: 'Forgot your password',
    contactManager: 'Please contact your manager to find your company code.',
    companyCode: 'Company Code',
    username: 'Username',
    password: 'Password',
    token: 'Token',
    next: 'Next',
    back: 'Back',
    googleTokenInstructions:
      'Please enter the One Time Password (OTP) generated by your Google Authenticator App',
    token_required: 'Token is required',
    google_token: 'One Time Password',
    password_required: 'Password is required',
    companyCode_required: 'Company code is required',
    email_required: 'Email or Username is required',
    customerUuid_required: 'Customer UUID is required',
    userUuid_required: 'User UUID is required',
    customerId_required: 'Customer ID is required',
    switchToEmailToken: 'Switch to email token',
    switchToGoogleAuthenticator: 'Switch to Google Authenticator',
    resetPassword: 'Reset Password',
    securityUpdate: 'Security Update',
    securityUpdateSubtitle:
      'Toast has updated its security procedures. We’ve emailed a password reset link to {{email}}. If you can’t find it in your inbox, please check your spam folder.',
    resendEmail: 'Resend Email',
    cantAccessEmail: "Can't access your email?",
    contactManagerEmail:
      'Please contact your manager to set a valid email address for your Toast Payroll account',
    email: 'Email',
    emailUsername: 'Email or Username'
  })

  i18n.addResourceBundle('es', 'login', {
    error: {
      unknown: 'Algo salió mal',
      unauthorized: 'Código de empresa, usuario o contraseña incorrectos',
      invalidToken: 'El token que ingresaste es incorrecto. Inténtalo de nuevo',
      translationFailed: 'No se pudo guardar la configuración de traducción',
      singleSignOnEnabled:
        'Error de restablecimiento de contraseña. La empresa tiene habilitado el inicio de sesión único. Comuníquese con su gerente.',
      companyCodeNotFound: 'Código de empresa no encontrado',
      legacyUsernameProvided:
        'Inicie sesión con su dirección de correo electrónico',
      customerAccessNotAllowed:
        'El código de la empresa en el que está intentando iniciar sesión no está activo en Toast Payroll. Si es un empleado, comuníquese con el gerente de su restaurante para conocer los próximos pasos.',
      expiredPassword: ' requerido',
      expiredPasswordLink: 'Restablecimiento de contraseña'
    },
    success: {
      passwordReset:
        'Se ha enviado un correo electrónico a su dirección de correo electrónico registrado con instrucciones. Si no recibe este correo electrónico dentro de 2 horas, verifique sus carpetas de SPAM e intente restablecer su contraseña nuevamente.',
      passwordSuccess: 'La contraseña ha sido restablecida exitosamente.'
    },
    welcome: 'Bienvenidos',
    twoStepVerification: 'Verificación De Pos Pasos',
    enterToken:
      'Ingrese el token de inicio de sesión que le enviamos a su correo electrónico',
    logIn: 'Inicie sesión en Toast Payroll',
    forgotCompanyCode: '¿No recuerda el código de su empresa?',
    troubleLoggingIn: '¿Problemas para iniciar sesión?',
    forceResetPassword: 'Restablecer su contraseña',
    forceResetPasswordInstructions:
      'Solicite un restablecimiento de contraseña y le enviaremos un correo electrónico para ayudarlo a volver a la normalidad.',
    dontRememberYourPassword: '¿No recuerda el código de su Contraseña',
    dontRememberYourPasswordInstructions:
      'Al reiniciar, se enviará un token de seguridad y más instrucciones a su dirección de correo electrónico registrado',
    forgotPassword: 'Restablecer la contraseña',
    contactManager:
      'Comuníquese con su gerente para encontrar el código de su empresa.',
    companyCode: 'Código de empresa',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    token: 'Token',
    google_token: 'Token de autenticación de Google',
    next: 'Siguiente',
    back: 'Atrás',
    token_required: 'Se requiere token',
    password_required: 'Se requiere contraseña',
    companyCode_required: 'El código de la empresa es obligatorio',
    email_required: 'Email o Nombre de Usuario es requerido',
    customerUuid_required: 'Se requiere el UUID del cliente',
    userUuid_required: 'Se requiere el UUID del usuario',
    customerId_required: 'Se requiere ID del cliente',
    switchToEmailToken: 'Cambiar a token del correo electrónico',
    switchToGoogleAuthenticator: 'Cambiar a Autenticador de Google',
    resetPassword: 'Restablecer la contraseña',
    securityUpdate: 'Actualización de seguridad',
    securityUpdateSubtitle:
      'Toast ha actualizado sus procedimientos de seguridad. Enviamos un vínculo para restablecer la contraseña por correo electrónico a {{email}}. Si no puede encontrarlo en su bandeja de entrada, verifique su carpeta de correo no deseado.',
    resendEmail: 'Reenviar email',
    cantAccessEmail: '¿No puedes acceder a tu correo electrónico?',
    contactManagerEmail:
      'Comuníquese con su gerente para establecer una dirección de correo electrónico válida para su cuenta de Toast Payroll ',
    email: 'Email',
    emailUsername: 'Correo Electronico o Nombre de Usuario'
  })

  if (!auth0BaseUrl || !auth0ClientId) {
    throw Error('auth0BaseUrl and auth0ClientId are missing from configuration')
  }

  useLoginNavigationReset()
  useQueryParamState()
  useLanguageSwitcher()
  useLoginCommandHandler()

  return <LoginLayout />
}
