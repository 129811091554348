import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import { Atoms } from '../atoms'
import { useResetPassword, useSetScreen } from '../Hooks'
import { LoginScreen } from '../Domain'

interface ErrorViewProps {
  message?: string | null
}

export const ErrorView: React.FC<ErrorViewProps> = ({ message }) => {
  const resetPassword = useResetPassword()
  const { t } = useTranslation('login')
  const error = useRecoilValue(Atoms.error)
  const isLegacyUsernameErrorType = useRecoilValue(
    Atoms.isLegacyUsernameErrorType
  )
  const isExpiredPasswordErrorType = useRecoilValue(
    Atoms.isExpiredPasswordErrorType
  )
  const isCompanyCodeNotFoundErrorType = useRecoilValue(
    Atoms.isCompanyCodeNotFoundErrorType
  )
  const setScreen = useSetScreen()

  const errorMessage = isLegacyUsernameErrorType ? error : error || message

  if (errorMessage) {
    return (
      <div className='error'>
        <div className='errorText'
              data-toast-track-id={isCompanyCodeNotFoundErrorType ? 'ec-login-company-code-not-found' : ''}
              data-testid={isCompanyCodeNotFoundErrorType ? 'forgot-company-code' : ''}>
          {isExpiredPasswordErrorType ? (
            <div className='block'>
              <span
                className='font-bold text-white underline cursor-pointer inline-link'
                onClick={() => {
                  setScreen(LoginScreen.COMPANY_CODE_AND_EMAIL)
                  resetPassword()
                }}
              >
                {t('error.expiredPasswordLink')}
              </span>
              {t(errorMessage)}
            </div>
          ) : (
            t(errorMessage)
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}
