import * as React from 'react'
import { ToastUser, ConfiguredWindow } from '@local/ec-app'
import { useWindowProvider } from './window'

type CurrentUserProviderProps = {
  children: React.ReactNode
}

export const CurrentUserContext = React.createContext<ToastUser>(
  null as unknown as ToastUser
)

export const CurrentUserProvider = ({ children }: CurrentUserProviderProps) => {
  const window = useWindowProvider()
  const user = (window as ConfiguredWindow).Toast.user

  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  )
}
