import * as yup from 'yup'
import { useRecoilValue } from 'recoil'
import * as React from 'react'

import { Validation } from '../Domain'
import { FormContainer } from '../components/FormContainer'
import { Formik } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { ForgotPasswordButton } from '../components/ForgotPasswordButton'
import { ErrorView } from '../components/ErrorView'
import { ButtonContainer } from '../components/ButtonContainer'
import { BackButton } from '../components/BackButton'
import { NextButton } from '../components/NextButton'
import { TroubleLoggingIn } from './TroubleLoggingIn'
import { useTranslation } from 'react-i18next'
import { Atoms } from '../atoms'
import { useSetPassword } from '../Hooks'
import { SuccessAlert } from './SuccessAlert'

class PasswordFormValues {
  private constructor(readonly password: string) {}

  static schema = yup
    .object({
      password: Validation.validatePassword
    })
    .defined()

  static of(obj: any): PasswordFormValues {
    return PasswordFormValues.schema.validateSync(obj, { abortEarly: false })
  }
}

export const PasswordForm: React.FC = () => {
  const { t } = useTranslation('login')
  const loginCommand = useRecoilValue(Atoms.loginCommand)
  const setPassword = useSetPassword()

  return (
    <FormContainer title={t('welcome')} subtitle={t('logIn')}>
      <Formik<PasswordFormValues>
        initialValues={{
          password: ''
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={PasswordFormValues.schema}
        onSubmit={(values: PasswordFormValues) => setPassword(values.password)}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} autoComplete='on'>
            <div className='mb-6'>
              <div className='inputName inputDisabled inputContainer'>
                {t('companyCode')}
              </div>
              <TextInputField
                id='companyCode'
                name='companyCode'
                testId='companyCode'
                disabled={true}
                value={loginCommand?.companyCode}
              />
              <div className='inputName inputDisabled inputContainer'>
                {t('emailUsername')}
              </div>
              <TextInputField
                type='email'
                id='email'
                name='email'
                testId='email'
                disabled={true}
                value={loginCommand?.email}
              />
              <div className='inputName inputContainer'>{t('password')}</div>
              <TextInputField
                type='password'
                autoComplete='current-password'
                id='password'
                name='password'
                testId='password'
                errorText={t(props.errors.password || '')}
                autoFocus={true}
              />
            </div>
            <ForgotPasswordButton />
            <ErrorView />
            <SuccessAlert />
            <ButtonContainer>
              <BackButton />
              <NextButton />
            </ButtonContainer>
            <TroubleLoggingIn />
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}
