import * as React from 'react'

interface FormContainerProps {
  title: string
  subtitle?: string
  children: React.ReactNode
}

export const FormContainer: React.FC<FormContainerProps> = ({
  title,
  subtitle,
  children
}) => (
  <div>
    <h1 className='mt-6 mb-4 type-headline-4 welcome'>{title}</h1>
    {subtitle && <p className='type-subhead flex justify-center'>{subtitle}</p>}
    {children}
  </div>
)
