import * as React from 'react'
import { useRecoilState } from 'recoil'
import { TextToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { Atoms } from '../atoms'

export const LanguageSwitcher: React.FC = () => {
  const [language, setLanguage] = useRecoilState(Atoms.language)
  const isSpanishActive = language === 'es'

  const changeLanguage = () => {
    const switchToLanguage = isSpanishActive ? 'en-US' : 'es'
    setLanguage(switchToLanguage)
  }

  return (
    <div className='flex justify-center mb-4'>
      <div className='w-56'>
        <TextToggleSwitch
          name='language'
          labels={['English', 'Español']}
          isActive={isSpanishActive}
          onChange={changeLanguage}
        />
      </div>
    </div>
  )
}
