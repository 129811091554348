import * as React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import {
  LoginScreen,
  LoginStatus,
  ErrorMessageType,
  Authenticate,
  SendTokenEmail,
  FindUser,
  ResetPassword,
  ValidateGoogleToken,
  LoginCommand,
  ValidateToken,
  CookieName
} from './Domain'
import {
  Redirect,
  TokenRequired,
  Unauthorized,
  PasswordResetSuccess,
  PasswordResetFailed,
  PayrollCustomerNotFound,
  ForceResetPassword,
  LegacyUsernameProvided,
  useApiProvider,
  LinkedUserNotFound,
  CustomerAccessNotAllowed,
  PasswordExpired
} from './providers/ApiProvider'
import { Atoms } from './atoms'
import { track } from '@local/tracking'
import { useConfig } from '@local/ec-app'
import { useTranslation } from 'react-i18next'

export const useQueryParam = () => new URLSearchParams(useLocation().search)

export const useClearError = () => {
  const setError = useSetRecoilState(Atoms.error)

  return () => setError('')
}

export const useClearAlert = () => {
  const setSuccessAlert = useSetRecoilState(Atoms.successAlert)

  return () => setSuccessAlert('')
}

export const useSetScreen = () => {
  const history = useHistory()
  const clearError = useClearError()
  return (screen: LoginScreen) => {
    if (screen === LoginScreen.FORGOT_PASSWORD) {
      clearError()
      track('Login (Auth0 Switcher) - Clicked Forgot Password Link')
    }

    history.push(screen)
  }
}

export const useScreen = () => {
  return useLocation().pathname
}

/**
 * Catch all that sets the screen back to the root login if the current command
 * state were to become null.
 */
export const useLoginNavigationReset = () => {
  const loginCommand = useRecoilValue(Atoms.loginCommand)
  const setScreen = useSetScreen()
  const screen = useScreen()

  React.useEffect(() => {
    if (
      loginCommand === null &&
      screen !== LoginScreen.COMPANY_CODE_AND_EMAIL
    ) {
      setScreen(LoginScreen.COMPANY_CODE_AND_EMAIL)
    }
  }, [loginCommand, screen, setScreen])
}

export const useGoBack = () => {
  const history = useHistory()
  const clearError = useClearError()
  const clearAlert = useClearAlert()

  return () => {
    clearAlert()
    clearError()
    history.goBack()
  }
}

export const useIsReauth = () => useRecoilValue(Atoms.isReauth)

export const useSetInvalidTokenResponse = () => {
  const [loginCommand, setLoginCommand] = useRecoilState(Atoms.loginCommand)
  const resetToLogin = useResetToLogin()
  const isTokenCommand = useRecoilValue(Atoms.isTokenCommand)
  const setCompanyCode = useSetRecoilState(Atoms.companyCode)
  const setError = useSetRecoilState(Atoms.error)
  const setLoginStatus = useSetRecoilState(Atoms.loginStatus)
  const setScreen = useSetScreen()

  return () => {
    if (isTokenCommand) {
      setCompanyCode(loginCommand?.companyCode ?? '')
      setLoginCommand(null)
      setError('error.invalidToken')
      setLoginStatus(LoginStatus.WAITING)
      setScreen(LoginScreen.COMPANY_CODE_AND_EMAIL)
    } else {
      resetToLogin()
    }
  }
}

export const useResetToLogin = () => {
  const setScreen = useSetScreen()
  const clearError = useClearError()

  return () => {
    clearError()
    setScreen(LoginScreen.COMPANY_CODE_AND_EMAIL)
  }
}

export const useSetUnknownError = () => {
  const setLoginStatus = useSetRecoilState(Atoms.loginStatus)
  const setError = useSetRecoilState(Atoms.error)

  return () => {
    setLoginStatus(LoginStatus.ERROR)
    setError(ErrorMessageType.UNKNOWN)
  }
}

export const useSetSmsGoogleAuthenticator = () => {
  const setHasSms = useSetRecoilState(Atoms.hasSms)
  const setScreen = useSetScreen()

  return (hasSms: boolean) => {
    setHasSms(hasSms)
    setScreen(LoginScreen.GOOGLE_AUTHENTICATOR)
  }
}

export const useFindLinkedUser = () => {
  const setLoginCommand = useSetRecoilState(Atoms.loginCommand)
  const clearError = useClearError()

  return (findUser: FindUser) => {
    clearError()
    setLoginCommand(findUser)
  }
}

export const useResetPassword = () => {
  const [loginCommand, setLoginCommand] = useRecoilState(Atoms.loginCommand)
  const setScreen = useSetScreen()
  const canResetPassword = useRecoilValue(Atoms.canResetPassword)
  const clearError = useClearError()
  const clearAlert = useClearAlert()

  return () => {
    if (canResetPassword) {
      setLoginCommand(ResetPassword.of(loginCommand))
    } else {
      setScreen(LoginScreen.COMPANY_CODE_AND_EMAIL)
    }

    clearAlert()
    clearError()
  }
}

const useSetToken: <T extends LoginCommand>(
  factory: (command: LoginCommand | null, token: string) => T
) => (token: string) => void = (factory) => {
  const isTokenCommand = useRecoilValue(Atoms.isTokenCommand)
  const [loginCommand, setLoginCommand] = useRecoilState(Atoms.loginCommand)
  const resetToLogin = useResetToLogin()
  const clearError = useClearError()

  return (token: string) => {
    if (isTokenCommand) {
      setLoginCommand(factory(loginCommand, token))
      clearError()
    } else {
      resetToLogin()
    }
  }
}

export const useSetValidateToken = () => {
  const setToken = useSetToken((command, token) =>
    ValidateToken.of({
      ...command,
      token
    })
  )

  return setToken
}

export const useSetGoogleToken = () => {
  const setToken = useSetToken((command, token) =>
    ValidateGoogleToken.of({
      ...command,
      token
    })
  )

  return setToken
}

export const useSwitchToEmailToken = () => {
  const [loginCommand, setLoginCommand] = useRecoilState(Atoms.loginCommand)
  const resetToLogin = useResetToLogin()
  const setScreen = useSetScreen()
  const setGoogleTfaEnabled = useSetRecoilState(Atoms.isGoogleTfaEnabled)

  return () => {
    if (Authenticate.is(loginCommand)) {
      setGoogleTfaEnabled(true)
      setLoginCommand(
        SendTokenEmail.of({
          companyCode: loginCommand.companyCode,
          email: loginCommand.email,
          password: loginCommand.password
        })
      )
      setScreen(LoginScreen.TOKEN)
    } else {
      resetToLogin()
    }
  }
}

export const useSwitchToGoogleToken = () => {
  const setScreen = useSetScreen()
  const setGoogleTfaEnabled = useSetRecoilState(Atoms.isGoogleTfaEnabled)

  return () => {
    setGoogleTfaEnabled(true)
    setScreen(LoginScreen.GOOGLE_AUTHENTICATOR)
  }
}

export const useNextButtonClickHandler = () => {
  const clearAlert = useClearAlert()
  const isLegacyUsernameErrorType = useRecoilValue(
    Atoms.isLegacyUsernameErrorType
  )
  const clearError = useClearError()

  return () => {
    if (isLegacyUsernameErrorType) {
      clearError()
    }
    clearAlert()
  }
}

export const useSetPassword = () => {
  const [loginCommand, setLoginCommand] = useRecoilState(Atoms.loginCommand)
  const resetToLogin = useResetToLogin()
  const clearError = useClearError()

  return (password: string) => {
    if (FindUser.is(loginCommand) || Authenticate.is(loginCommand)) {
      setLoginCommand(
        Authenticate.of({
          companyCode: loginCommand.companyCode,
          email: loginCommand.email,
          password
        })
      )
      clearError()
    } else {
      resetToLogin()
    }
  }
}

export const useQueryParamState = () => {
  const queryParam = useQueryParam()
  const returnUrl = queryParam.get('ReturnUrl') || ''
  const companyCode = queryParam.get('companyCode') || ''
  const isReauth = queryParam.get('reauth') === 'true'
  const setCompanyCode = useSetRecoilState(Atoms.companyCode)
  const setReturnUrl = useSetRecoilState(Atoms.returnUrl)
  const setIsReauth = useSetRecoilState(Atoms.isReauth)

  // Only capture on initial load.
  // If the params are updated they will NOT be reflected.
  React.useEffect(() => {
    setCompanyCode(companyCode)
    setReturnUrl(returnUrl)
    setIsReauth(isReauth)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Switches the translation language when the language state changes.
 */
export const useLanguageSwitcher = () => {
  const { i18n } = useTranslation('login')
  const [language, setLanguage] = useRecoilState(Atoms.language)

  React.useEffect(() => {
    const abortController = new AbortController()
    const changeLanguage = async () => {
      try {
        const response = await fetch('/CompanyCode/ChangeLanguage', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ language }),
          signal: abortController.signal
        })
        if (response.ok) {
          await i18n.changeLanguage(language)
        } else {
          // Rollback if this fails
          setLanguage(i18n.language)
        }
      } catch (e) {
        console.error(
          'Error occured when updating ec session language from login page',
          e
        )
      }
    }

    if (language !== i18n.language) {
      changeLanguage()
    }

    return () => abortController.abort()
  }, [i18n, language, setLanguage])
}

// store the company code for future autofills
const setCompanyCodeCookie = (value: String) =>
  (document.cookie = `${CookieName.COMPANY_CODE}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/;`)

export const useLoginCommandHandler = () => {
  const loginCommand = useRecoilValue(Atoms.loginCommand)
  const setScreen = useSetScreen()
  const returnUrl = useRecoilValue(Atoms.returnUrl)
  const setLoginStatus = useSetRecoilState(Atoms.loginStatus)
  const setError = useSetRecoilState(Atoms.error)
  const hasSms = useRecoilValue(Atoms.hasSms)
  const setSuccessAlert = useSetRecoilState(Atoms.successAlert)
  const setInvalidTokenResponse = useSetInvalidTokenResponse()
  const setUnknownError = useSetUnknownError()
  const setSmsGoogleAuthenticator = useSetSmsGoogleAuthenticator()
  const {
    findLinkedPayrollUser,
    logIn,
    resetPassword,
    sendTokenEmail,
    validateGoogleToken,
    validateToken
  } = useApiProvider()
  const { auth0BaseUrl, auth0ClientId, payrollLoginCookieDomain } = useConfig()
  React.useEffect(() => {
    const abortController = new AbortController()

    const effect = async () => {
      if (loginCommand == null) {
        track('Login (Auth0 Switcher) - Viewed Landing Page')
        return
      } else if (FindUser.is(loginCommand)) {
        track('Login (Auth0 Switcher) - Clicked Next on Company Code Page')
        try {
          setLoginStatus(LoginStatus.REQUESTING)

          //user has initiated payroll login, set the payroll login cookie
          document.cookie = `${CookieName.IS_PAYROLL_LOGIN};domain=${payrollLoginCookieDomain};secure;`

          const result = await findLinkedPayrollUser(returnUrl, loginCommand)
          setLoginStatus(LoginStatus.WAITING)

          if (result instanceof LinkedUserNotFound) {
            track('Login (Auth0 Switcher) - Viewed Password Entry Page')
            setCompanyCodeCookie(loginCommand.companyCode)
            setScreen(LoginScreen.PASSWORD)
          } else if (result instanceof Redirect) {
            setCompanyCodeCookie(loginCommand.companyCode)
            window.location.assign(result.url)
          } else if (result instanceof PayrollCustomerNotFound) {
            setError(ErrorMessageType.COMPANY_CODE_NOT_FOUND)
          } else if (result instanceof LegacyUsernameProvided) {
            setError(ErrorMessageType.LEGACY_USERNAME_PROVIDED)
          } else if (result instanceof CustomerAccessNotAllowed) {
            setError(ErrorMessageType.CUSTOMER_ACCESS_NOT_ALLOWED)
          } else {
            // result = null
            setError(ErrorMessageType.UNKNOWN)
          }
        } catch (error) {
          console.error(error)
          setUnknownError()
        }
      } else if (Authenticate.is(loginCommand)) {
        track(`Login (Auth0 Switcher) - Clicked Next on Password Entry Page`)
        try {
          setLoginStatus(LoginStatus.REQUESTING)
          const result = await logIn(
            loginCommand,
            abortController.signal,
            returnUrl
          )
          if (result instanceof Redirect) {
            window.location.assign(result.url)
          } else {
            setLoginStatus(LoginStatus.WAITING)
            if (result instanceof Unauthorized) {
              setError(ErrorMessageType.UNAUTHORIZED)
            } else if (result instanceof PasswordExpired) {
              setError(ErrorMessageType.EXPIRED_PASSWORD)
            } else if (result instanceof TokenRequired) {
              track('Login (Auth0 Switcher) - Viewed Email Token Entry Page')
              setScreen(LoginScreen.TOKEN)
            } else if (result instanceof ForceResetPassword) {
              track('Login (Auth0 Switcher) - Force reset password')
              setScreen(LoginScreen.RESET_PASSWORD)
            } else {
              track('Login (Auth0 Switcher) - Viewed Google OTP Entry Page')
              setSmsGoogleAuthenticator(result.hasSms)
            }
          }
        } catch (error) {
          console.error(error)
          setUnknownError()
        }
      } else if (ValidateToken.is(loginCommand)) {
        track('Login (Auth0 Switcher) - Clicked Next on Email Token Entry Page')
        try {
          setLoginStatus(LoginStatus.REQUESTING)
          const result = await validateToken(
            loginCommand,
            abortController.signal,
            returnUrl
          )
          if (result instanceof Redirect) {
            window.location.assign(result.url)
          } else {
            setInvalidTokenResponse()
          }
        } catch (error) {
          console.error(error)
          setUnknownError()
        }
      } else if (ValidateGoogleToken.is(loginCommand)) {
        track('Login (Auth0 Switcher) - Clicked Next on Google OTP Entry Page')
        try {
          setLoginStatus(LoginStatus.REQUESTING)
          const result = await validateGoogleToken(
            loginCommand,
            abortController.signal
          )
          if (result instanceof Redirect) {
            window.location.assign(result.url)
          } else {
            setLoginStatus(LoginStatus.WAITING)
            setInvalidTokenResponse()
          }
        } catch (error) {
          console.error(error)
          setUnknownError()
        }
      } else if (ResetPassword.is(loginCommand)) {
        try {
          setLoginStatus(LoginStatus.REQUESTING)
          const result = await resetPassword(loginCommand)
          setLoginStatus(LoginStatus.WAITING)

          if (result instanceof PasswordResetSuccess) {
            setSuccessAlert('success.passwordReset')
          } else if (result instanceof PasswordResetFailed) {
            setError(result.message)
          }
        } catch (error) {
          console.error(error)
          setUnknownError()
        }
      } else if (SendTokenEmail.is(loginCommand)) {
        try {
          setLoginStatus(LoginStatus.REQUESTING)
          await sendTokenEmail(loginCommand, hasSms, abortController.signal)
          setLoginStatus(LoginStatus.WAITING)
        } catch (error) {
          console.error(error)
          setUnknownError()
        }
      }
    }

    effect()

    return () => abortController.abort()
  }, [loginCommand, auth0BaseUrl, auth0ClientId, returnUrl, hasSms]) // eslint-disable-line react-hooks/exhaustive-deps
}
