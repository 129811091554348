import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Alert } from '@toasttab/buffet-pui-alerts'
import { TextInputField } from '@toasttab/buffet-pui-forms'

import { track } from '@local/tracking'

import { FormContainer } from '../components/FormContainer'
import { FindUser, isLegacyUsernameErrorType } from '../Domain'
import { ErrorView } from '../components/ErrorView'
import { SuccessAlert } from '../components/SuccessAlert'
import { ButtonContainer } from '../components/ButtonContainer'
import { NextButton } from '../components/NextButton'
import { useQueryParam, useFindLinkedUser } from '../Hooks'
import { TroubleLoggingIn } from './TroubleLoggingIn'
import { Atoms } from '../atoms'
import { ValidateFormOnChange } from './ValidateFormOnChange'

import { CookieName } from '../Domain'

const RESET_PASSWORD = 'resetPassword'

export const ForgotCompanyCode: React.FC = () => {
  const [isShowing, setIsShowing] = React.useState(false)
  const { t } = useTranslation('login')

  React.useEffect(() => {
    if (isShowing) {
      track('Login (Auth0 Switcher) - Clicked Forgot Company Code Link')
    }
  }, [isShowing])

  return (
    <div className='forgotLink'>
      {isShowing ? (
        <Alert variant='neutral' showIcon onDismiss={() => setIsShowing(false)}>
          {t('contactManager')}
        </Alert>
      ) : (
        <button
          type='button'
          onClick={() => setIsShowing(true)}
          className='w-full text-left hover:underline type-subhead'
          data-toast-track-id='ec-login-forgot-company-code'
          data-testid='forgot-company-code'
        >
          {t('forgotCompanyCode')}
        </button>
      )}
    </div>
  )
}

// https://stackoverflow.com/a/15724300
// cookies are stored as "{name}={value}; {name}={value}; ...", so use this to extract them
const getCookie = (): string | null => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + CookieName.COMPANY_CODE + '=')

  return parts.pop()?.split(';').shift() || null
}

const useCompanyCodeAutofill = (): string => {
  const cookieVal = getCookie()
  const loginCommand = useRecoilValue(Atoms.loginCommand)
  const companyCode = useRecoilValue(Atoms.companyCode)
  const queryParam = useQueryParam()
  const companyCodeParam = queryParam.get('companyCode')

  return (
    loginCommand?.companyCode ||
    companyCodeParam ||
    cookieVal ||
    companyCode ||
    ''
  )
}

export const CompanyCodeAndEmailForm: React.FC = () => {
  const { t } = useTranslation('login')
  const queryParam = useQueryParam()
  const message = queryParam.get('message')
  const successParam = queryParam.get('success')
  const successMessage =
    successParam === RESET_PASSWORD ? t('success.passwordSuccess') : ''
  const loginCommand = useRecoilValue(Atoms.loginCommand)
  const error = useRecoilValue(Atoms.error)
  const findLinkedUser = useFindLinkedUser()
  const companyCodeAutofill = useCompanyCodeAutofill()

  return (
    <FormContainer title={t('welcome')} subtitle={t('logIn')}>
      <Formik<FindUser>
        initialValues={
          {
            companyCode: companyCodeAutofill,
            email: loginCommand?.email || ''
          } as FindUser
        }
        validateOnBlur={false}
        validateOnChange={false}
        // Tie this error message into formiks form validation since it's not part
        // of the standard form validation
        validate={() =>
          isLegacyUsernameErrorType(error) ? { email: error } : undefined
        }
        validationSchema={FindUser.schema}
        onSubmit={(values: FindUser): void =>
          findLinkedUser(FindUser.copyOf(values))
        }
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} autoComplete='on'>
            <div className='mb-6'>
              <div className='inputName inputContainer'>{t('companyCode')}</div>
              <TextInputField
                value={props.values.companyCode}
                autoComplete='organization'
                id='companyCode'
                name='companyCode'
                testId='companyCode'
                errorText={t(props.errors.companyCode || '')}
                autoFocus={!props.values?.companyCode}
              />
              {/* keeping the form key as "email" since "username" is just temporary until we merge all users and this is just a display change */}
              <div className='inputName inputContainer'>
                {t('emailUsername')}
              </div>
              <TextInputField
                value={props.values.email}
                autoComplete='email'
                id='email'
                name='email'
                testId='email'
                errorText={t(props.errors.email || '')}
                autoFocus={!!props.values?.companyCode}
              />
            </div>
            {/* Validate the form based off the error from the login request only after it's been submitted once */}
            {!!props.submitCount && <ValidateFormOnChange value={error} />}
            <ErrorView message={message} />
            <SuccessAlert message={successMessage} />
            <ForgotCompanyCode />
            <ButtonContainer>
              <NextButton />
            </ButtonContainer>
            <TroubleLoggingIn />
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}
