import * as React from 'react'
import './carousel.css'

export default () => {
  const [slides, setSlides] = React.useState([''])

  React.useEffect(() => {
    const abortController = new AbortController()

    const loadCarouselData = async () => {
      try {
        setSlides(await getCarouselData())
      } catch (e) {
        console.error(e)
      }
    }

    loadCarouselData()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <>
      <div className='flex flex-col flex-wrap-reverse justify-between h-screen md:flex-row toast'>
        <div
          className='h-full login-container flex-item'
          style={{ flexBasis: '100%' }}
        >
          <div className='float-left w-full h-full ml-0'>
            <div className='center-cropped'>
              <div className='slider'>
                <div
                  className='slides'
                  dangerouslySetInnerHTML={{ __html: slides.join('') }}
                ></div>
                <div className='bullets' />
              </div>
              <p className='login-footer'>
                Powered by{' '}
                <a
                  className='toast-footer-link'
                  href='http://pos.toasttab.com/?source=footer'
                >
                  Toast
                </a>
                <span>•</span>© Toast, Inc.
                <span id='copyright-date'>2021.</span>All Rights Reserved.
                <span>•</span>
                <a
                  className='text-white'
                  href='http://pos.toasttab.com/privacy'
                  id='footer-privacy-policy'
                >
                  Privacy Policy
                </a>
                <span>•</span>
                <a
                  className='text-white'
                  href='http://pos.toasttab.com/terms-of-service'
                  id='footer-terms-of-service'
                >
                  Terms of Service
                </a>
                <span>•</span>
                <a
                  className='text-white'
                  href='http://blog.toasttab.com/?source=footer'
                  id='footer-blog'
                >
                  Toast&nbsp;Blog
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const getCarouselData = async () => {
  // returned in case of fetch errors
  const defaultCarouselContent = [
    '<div class="slide' +
      ' active' +
      '" style="background-image:linear-gradient(rgba(0,0,0,0.45),rgba(0,0,0,0.45))' +
      ",url('" +
      'https://cdn.toasttab.com/static/760b74b85be84be80dea827930a5c532f85cc51b/projects/ec/login/default_carousel_image.jpg' +
      "');>" +
      '  <div class="slideDetails">' +
      '  </div>' +
      '</div>'
  ]

  try {
    const response = await fetch('https://pos.toasttab.com/toast-login.json')
    if (response.ok) {
      const { data } = await response.json()
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      const slides = data.map((carouselCard: any, index: number) => {
        var date = new Date(carouselCard.date * 1000)
        var formattedDate =
          monthNames[date.getMonth()] +
          ' ' +
          date.getDate() +
          ', ' +
          date.getFullYear()
        var imageOverlayAlpha = carouselCard.imageOverlayAlpha / 100

        return (
          '<div class="slide' +
          (index === 0 ? ' active' : '') +
          '" style="background-image:linear-gradient(rgba(0,0,0,' +
          imageOverlayAlpha +
          '),rgba(0,0,0,' +
          imageOverlayAlpha +
          ")),url('" +
          carouselCard.imageUrl +
          '\')">' +
          '  <div class="slideDetails">' +
          '    <div class="rectangle-top"></div>' +
          (carouselCard.url
            ? '    <a target="_blank" href="' + carouselCard.url + '">'
            : '') +
          (carouselCard.date
            ? '      <div class="article-date">' + formattedDate + '</div>'
            : '') +
          (carouselCard.title
            ? '      <div class="article-title">' +
              carouselCard.title +
              '</div>'
            : '') +
          (carouselCard.subtitle
            ? '      <div class="article-subtitle">' +
              carouselCard.subtitle +
              '</div>'
            : '') +
          (carouselCard.urlText
            ? '      <span class="view-post">' +
              carouselCard.urlText +
              '</span>'
            : '') +
          (carouselCard.url ? '    </a>' : '') +
          '    <div class="rectangle-bottom"></div>' +
          '  </div>' +
          '</div>'
        )
      })

      return slides
    } else {
      return defaultCarouselContent
    }
  } catch {
    return defaultCarouselContent
  }
}
