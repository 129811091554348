import * as yup from 'yup'
import { Validation } from '../Domain'
import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { FormContainer } from '../components/FormContainer'
import { Formik } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { ErrorView } from '../components/ErrorView'
import { BackButton } from '../components/BackButton'
import { NextButton } from '../components/NextButton'
import { ButtonContainer } from '../components/ButtonContainer'
import { useTranslation } from 'react-i18next'
import { useSetValidateToken, useSwitchToGoogleToken } from '../Hooks'
import { Atoms } from '../atoms'

export class TokenFormValues {
  private constructor(readonly token: string) {}

  static schema = yup
    .object({
      token: Validation.validateToken
    })
    .defined()

  static of(obj: any): TokenFormValues {
    return TokenFormValues.schema.validateSync(obj, { abortEarly: false })
  }
}

export const TokenForm: React.FC = () => {
  const { t } = useTranslation('login')
  const setValidateToken = useSetValidateToken()
  const switchToGoogleToken = useSwitchToGoogleToken()
  const isGoogleTfaEnabled = useRecoilValue(Atoms.isGoogleTfaEnabled)

  return (
    <FormContainer title={t('twoStepVerification')} subtitle={t('enterToken')}>
      <Formik<TokenFormValues>
        initialValues={{ token: '' }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={TokenFormValues.schema}
        onSubmit={(values: TokenFormValues) => setValidateToken(values.token)}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className='inputName inputContainer'>{t('Token')}</div>
            <div className='mb-6'>
              <TextInputField
                autoComplete='one-time-code'
                id='token'
                name='token'
                testId='token'
                errorText={t(props.errors.token || '')}
                autoFocus={true}
              />
            </div>
            <ErrorView />
            {isGoogleTfaEnabled && (
              <div className='forgotLink'>
                <button
                  type='button'
                  onClick={() => switchToGoogleToken()}
                  className='hover:underline type-subhead text-left w-full'
                >
                  {t('switchToGoogleAuthenticator')}
                </button>
              </div>
            )}
            <ButtonContainer>
              <BackButton />
              <NextButton />
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}
