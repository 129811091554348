import * as React from 'react'
import { FormContainer } from '../components/FormContainer'
import { useTranslation } from 'react-i18next'
import { SuccessAlert } from '../components/SuccessAlert'
import { ErrorView } from '../components/ErrorView'
import { ButtonContainer } from '../components/ButtonContainer'
import { BackButton } from '../components/BackButton'
import { Atoms } from '../atoms'
import { useRecoilValue } from 'recoil'
import { track } from '@local/tracking'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useResetPassword } from '../Hooks'

export const CantAccessEmail: React.FC = () => {
  const [isShowing, setIsShowing] = React.useState(false)
  const { t } = useTranslation('login')

  React.useEffect(() => {
    if (isShowing) {
      track("Login (Auth0 Switcher) - Clicked Can't Access Email Link")
    }
  }, [isShowing])

  return (
    <div className='cantAccessEmailLink'>
      {isShowing ? (
        <Alert variant='neutral' showIcon onDismiss={() => setIsShowing(false)}>
          {t('contactManagerEmail')}
        </Alert>
      ) : (
        <button
          type='button'
          onClick={() => setIsShowing(true)}
          className='w-full text-left hover:underline type-subhead'
        >
          {t('cantAccessEmail')}
        </button>
      )}
    </div>
  )
}

export const SecurityResetForm: React.FC = () => {
  const { t } = useTranslation('login')
  const isRequesting = useRecoilValue(Atoms.isRequesting)
  const resetPassword = useResetPassword()
  const loginCommand = useRecoilValue(Atoms.loginCommand)

  return (
    <FormContainer title={t('securityUpdate')}>
      <div className='mb-6'>
        {loginCommand?.email
          ? t('securityUpdateSubtitle', { email: loginCommand.email })
          : t('contactManagerEmail')}
      </div>
      <ErrorView />
      <SuccessAlert />
      <CantAccessEmail />
      <ButtonContainer>
        <BackButton />
        <button
          type='button'
          data-test-id='sendPasswordResetEmail'
          disabled={isRequesting}
          className='flex items-center justify-center loginButton'
          onClick={() => resetPassword()}
        >
          {t('resendEmail')}
        </button>
      </ButtonContainer>
    </FormContainer>
  )
}
