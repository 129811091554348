import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { FormContainer } from '../components/FormContainer'
import { ErrorView } from '../components/ErrorView'
import { SuccessAlert } from '../components/SuccessAlert'
import { ButtonContainer } from '../components/ButtonContainer'
import { BackButton } from '../components/BackButton'
import { useTranslation } from 'react-i18next'
import { Atoms } from '../atoms'
import { useResetPassword } from '../Hooks'

export const ResetPasswordForm: React.FC = () => {
  const { t } = useTranslation('login')
  const isRequesting = useRecoilValue(Atoms.isRequesting)
  const resetPassword = useResetPassword()

  return (
    <FormContainer title={t('welcome')}>
      <div className='mb-6'>{t('forceResetPasswordInstructions')}</div>
      <ErrorView />
      <SuccessAlert />
      <ButtonContainer>
        <BackButton />
        <button
          type='button'
          data-test-id='resetPassword'
          disabled={isRequesting}
          className='flex items-center justify-center loginButton'
          onClick={() => resetPassword()}
        >
          {t('forceResetPassword')}
        </button>
      </ButtonContainer>
    </FormContainer>
  )
}
