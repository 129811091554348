import * as React from 'react'
import { ApolloClient, ApolloError, gql, InMemoryCache } from '@apollo/client'
import {
  Authenticate,
  FindUser,
  LinkedPayrollUser,
  LinkedPayrollUserAndNonce,
  ResetPassword,
  SendTokenEmail,
  ValidateGoogleToken,
  ValidateToken,
  LoginErrorType,
  LoginErrorResponse
} from '../Domain'
import { useConfig, useOrigin } from '@local/ec-app'
import { useIsReauth } from '../Hooks'

//#region utils
const OriginMatcher = /^https?:\/\//

/**
 * Determines if a url has an origin.
 */
const hasOrigin = (url: string) => OriginMatcher.test(url)

/**
 * Strips the origin from a url and returns it's pathname.
 */
const toPathname = (url: string | null | undefined) => {
  if (!url) {
    return null
  }

  if (hasOrigin(url)) {
    try {
      return new URL(url).pathname
    } catch {
      return null
    }
  }

  return url.startsWith('/') ? url : `/${url}`
}

//#endregion
const LOGIN_GRAPH_PATH = '/login/graph'
const AUTH0_AUDIENCE = 'https://toast-users-api/'
const TOAST_UUID = '60381325-9064-11ea-b011-02e9bbf430ca'

const COMPANY_CODE_LOGIN_GQL = gql`
  query ($request: CompanyCodeAndEmail) {
    findLinkedPayrollUser(request: $request) {
      user {
        customerUuid
        userUuid
        customerId
        companyCode
      }
      loginNonce
    }
  }
`

const RESET_PASSWORD_GQL = gql`
  mutation ($input: ResetPasswordInput) {
    resetPassword(input: $input)
  }
`

const toQueryString = (params: Map<string, string>): string => {
  const entries = []
  for (const [key, value] of params.entries()) {
    if (value) {
      entries.push(`${key}=${escape(value).replace('+', '%2B')}`)
    }
  }
  return entries.join('&')
}

export class ForceResetPassword {}

export class PasswordExpired {}
export class GoogleAuthenticatorRequired {
  constructor(readonly hasSms: boolean) {}
}

export class TokenRequired {
  constructor(readonly hasSms: boolean) {}
}

export class Redirect {
  constructor(readonly url: string) {}
}

export class Unauthorized {
  constructor(readonly message: string) {}
}

export class PayrollCustomerNotFound {
  constructor(readonly companyCode: string) {}
}

export class LegacyUsernameProvided {
  constructor(readonly username: string) {}
}

export class CustomerAccessNotAllowed {
  constructor(readonly companyCode: string) {}
}

// this object is the generic "proceed to password screen" object
export class LinkedUserNotFound {}

type LoginResult =
  | TokenRequired
  | Redirect
  | Unauthorized
  | GoogleAuthenticatorRequired
  | ForceResetPassword

type ValidateTokenResult = Redirect | Unauthorized

type ValidateGoogleTokenResult = Redirect | Unauthorized

type FindLinkedPayrollUserResult =
  | LinkedPayrollUser
  | LinkedUserNotFound
  | Redirect
  | PayrollCustomerNotFound
  | LegacyUsernameProvided
  | CustomerAccessNotAllowed
  | null

export class PasswordResetSuccess {}

export class PasswordResetFailed {
  constructor(readonly message: string = '') {}
}

type PasswordResetResult = PasswordResetSuccess | PasswordResetFailed

const bustNavigationCache = (companyCode: string) =>
  sessionStorage.removeItem(`${companyCode}:navigation`)

export const ApiContext = React.createContext<{
  auth0LoginUrl: (
    companyCodeAndEmail: FindUser,
    linkedPayrollUserAndNonce: LinkedPayrollUserAndNonce,
    returnUrl?: string | null
  ) => string
  sendTokenEmail: (
    command: SendTokenEmail,
    hasSms: boolean,
    signal: AbortSignal
  ) => Promise<void>
  validateGoogleToken: (
    command: ValidateGoogleToken,
    signal: AbortSignal
  ) => Promise<ValidateGoogleTokenResult>
  logIn: (
    command: Authenticate,
    signal: AbortSignal,
    returnUrl?: string
  ) => Promise<LoginResult>
  findLinkedPayrollUser: (
    returnUrl: string | null,
    command: FindUser
  ) => Promise<FindLinkedPayrollUserResult>
  resetPassword: (command: ResetPassword) => Promise<PasswordResetResult>
  validateToken: (
    command: ValidateToken,
    signal: AbortSignal,
    returnUrl?: string
  ) => Promise<ValidateTokenResult>
}>({} as any)

export const useApiProvider = () => React.useContext(ApiContext)

export const ApiProvider: React.FC = ({ children }) => {
  const { auth0BaseUrl, auth0ClientId } = useConfig()
  const baseUrl = useOrigin()
  const [client] = React.useState(
    () =>
      new ApolloClient({
        uri: LOGIN_GRAPH_PATH,
        cache: new InMemoryCache()
      })
  )

  const isReauth = useIsReauth()
  const auth0LoginUrl = React.useCallback(
    (
      companyCodeAndEmail: FindUser,
      linkedPayrollUserAndNonce: LinkedPayrollUserAndNonce,
      returnUrl?: string | null
    ) => {
      const callbackUrl = `${baseUrl}/login/callback`
      const user = linkedPayrollUserAndNonce.user

      const state = window.btoa(
        JSON.stringify({
          email: companyCodeAndEmail.email,
          customerUuid: user.customerUuid,
          customerId: user.customerId,
          userUuid: user.userUuid,
          companyCode: user.companyCode,
          returnUrl: returnUrl,
          loginNonce: linkedPayrollUserAndNonce.loginNonce
        })
      )

      const params = new Map([
        ['scope', 'openid profile'],
        ['client_id', auth0ClientId],
        ['response_type', 'code'],
        ['audience', AUTH0_AUDIENCE],
        ['state', state],
        ['redirect_uri', callbackUrl],
        ['login_hint', companyCodeAndEmail.email]
      ])

      if (
        isReauth ||
        user.customerUuid === TOAST_UUID ||
        (user.companyCode && user.companyCode.toLowerCase() === 'toast')
      ) {
        params.set('prompt', 'login')
      }

      const queryString = toQueryString(params)

      return `${auth0BaseUrl}/authorize?${queryString}`
    },
    [auth0BaseUrl, auth0ClientId, baseUrl, isReauth]
  )

  const sendTokenEmail = React.useCallback(
    async (
      command: SendTokenEmail,
      hasSms: boolean,
      signal: AbortSignal
    ): Promise<void> => {
      const url = `${baseUrl}/Login/${command.companyCode}/TFAQueueMessage`
      const init: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        signal,
        body: JSON.stringify({
          username: command.email,
          password: command.password,
          sendSmsMessage: hasSms
        })
      }

      const response = await fetch(url, init)
      if (response.ok) {
        const json = await response.json()
        if (json.hasError) {
          throw Error(response.statusText)
        }
      } else {
        throw Error(response.statusText)
      }
    },
    [baseUrl]
  )

  const validateGoogleToken = React.useCallback(
    async (
      command: ValidateGoogleToken,
      signal: AbortSignal
    ): Promise<ValidateGoogleTokenResult> => {
      const url = `${baseUrl}/Login/${command.companyCode}/TFA`
      const init: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        signal,
        body: JSON.stringify({
          username: command.email,
          password: command.password,
          gaPin: command.token,
          usingGoogleAuth: true
        })
      }

      const response = await fetch(url, init)
      if (response.ok) {
        const json = await response.json()
        if (json.hasError) {
          return new Unauthorized(json.errorMessage)
        } else if (json.obj && json.obj.url) {
          return new Redirect(json.obj.url)
        } else {
          return new Redirect(`/${command.companyCode}/dashboard`)
        }
      } else {
        throw Error(response.statusText)
      }
    },
    [baseUrl]
  )

  const logIn = React.useCallback(
    async (
      command: Authenticate,
      signal: AbortSignal,
      returnUrl?: string
    ): Promise<LoginResult> => {
      const url = `${baseUrl}/Login/${command.companyCode}/Validate`
      const init: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        signal,
        body: JSON.stringify({
          username: command.email,
          password: command.password,
          isPublic: true
        })
      }

      const response = await fetch(url, init)
      if (response.ok) {
        const json = await response.json()

        if (json.hasError) {
          if (json.obj && json.obj.userId === -7) {
            return new ForceResetPassword()
          } else if (json.obj && json.obj.userId === -1) {
            return new PasswordExpired()
          } else {
            return new Unauthorized(json.errorMessage)
          }
        } else if (json.obj) {
          if (json.obj.userId === -99) {
            return new TokenRequired(json.obj.hasSms === 1)
          } else if (json.obj.userId === -97) {
            return new GoogleAuthenticatorRequired(json.obj.hasSms === 1)
          } else if (json.obj.url) {
            return new Redirect(json.obj.url)
          }
        }
        return new Redirect(
          toPathname(returnUrl) || `/${command.companyCode}/dashboard`
        )
      } else {
        throw Error(response.statusText)
      }
    },
    [baseUrl]
  )

  const findLinkedPayrollUser = React.useCallback(
    async (
      returnUrl: string | null,
      command: FindUser
    ): Promise<FindLinkedPayrollUserResult> => {
      try {
        const result: any = await client.query({
          query: COMPANY_CODE_LOGIN_GQL,
          variables: {
            request: {
              companyCode: command.companyCode,
              email: command.email
            }
          }
        })

        const linkedPayrollUserAndNonce = LinkedPayrollUserAndNonce.of(
          result.data.findLinkedPayrollUser
        )
        //an auth0 user passed the company code screen
        bustNavigationCache(command.companyCode)
        return new Redirect(
          auth0LoginUrl(command, linkedPayrollUserAndNonce, returnUrl)
        )
      } catch (error) {
        const errorResponse = (error as ApolloError).graphQLErrors[0]
          ?.extensions?.response.body.errors?.[0]
        const errorType = errorResponse?.type as LoginErrorType | null

        switch (errorType) {
          case LoginErrorType.PAYROLL_CUSTOMER_NOT_FOUND:
            return new PayrollCustomerNotFound(
              (
                errorResponse.obj as LoginErrorResponse<typeof errorType>
              )?.identifier
            )
          case LoginErrorType.LEGACY_USERNAME_PROVIDED:
            return new LegacyUsernameProvided(
              (
                errorResponse.obj as LoginErrorResponse<typeof errorType>
              )?.username
            )
          case LoginErrorType.CUSTOMER_ACCESS_NOT_ALLOWED:
            return new CustomerAccessNotAllowed(
              (
                errorResponse.obj as LoginErrorResponse<typeof errorType>
              )?.companyCode
            )
          case LoginErrorType.PAYROLL_USER_NOT_FOUND:
          case LoginErrorType.LINKED_USER_NOT_FOUND:
            //an unlinked user passed the company code screen (unknown usernames count as unlinked!)
            bustNavigationCache(command.companyCode)
            return new LinkedUserNotFound()
          default: {
            return null
          }
        }
      }
    },
    [auth0LoginUrl, client]
  )

  const resetPassword = React.useCallback(
    async (command: ResetPassword): Promise<PasswordResetResult> => {
      try {
        await client.mutate({
          mutation: RESET_PASSWORD_GQL,
          variables: {
            input: {
              companyCode: command.companyCode,
              email: command.email
            }
          }
        })
        return new PasswordResetSuccess()
      } catch (error) {
        console.error('Reset password request failed', error)
        if (error instanceof ApolloError && error.message.startsWith('400')) {
          const errorResponse =
            error.graphQLErrors[0]?.extensions?.response.body
          if (errorResponse?.type) {
            if (errorResponse.type === 'single_sign_on_enabled') {
              return new PasswordResetFailed('error.singleSignOnEnabled')
            } else if (
              errorResponse.type === 'queue_password_reset_email_failed'
            ) {
              return new PasswordResetFailed('error.unknown')
            } else {
              return new PasswordResetSuccess()
            }
          } else {
            if (errorResponse?.message === 'SingleSignOnEnabled') {
              return new PasswordResetFailed('error.singleSignOnEnabled')
            } else if (
              errorResponse?.message === 'QueuePasswordResetEmailFailed'
            ) {
              return new PasswordResetFailed('error.unknown')
            } else {
              return new PasswordResetSuccess()
            }
          }
        } else {
          return new PasswordResetFailed('error.unknown')
        }
      }
    },
    [client]
  )

  const validateToken = React.useCallback(
    async (
      command: ValidateToken,
      signal: AbortSignal,
      returnUrl?: string
    ): Promise<ValidateTokenResult> => {
      const url = `${baseUrl}/Login/${command.companyCode}/TFA`
      const init: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        signal,
        body: JSON.stringify({
          username: command.email,
          password: command.password,
          token: command.token
        })
      }

      const response = await fetch(url, init)
      if (response.ok) {
        const json = await response.json()
        if (json.hasError) {
          return new Unauthorized(json.errorMessage)
        } else if (json.obj && json.obj.url) {
          return new Redirect(json.obj.url)
        } else {
          return new Redirect(
            toPathname(returnUrl) || `/${command.companyCode}/dashboard`
          )
        }
      } else {
        throw Error(response.statusText)
      }
    },
    [baseUrl]
  )

  const api = React.useMemo(
    () => ({
      validateToken,
      auth0LoginUrl,
      findLinkedPayrollUser,
      logIn,
      resetPassword,
      sendTokenEmail,
      validateGoogleToken
    }),
    [
      validateToken,
      auth0LoginUrl,
      findLinkedPayrollUser,
      logIn,
      resetPassword,
      sendTokenEmail,
      validateGoogleToken
    ]
  )

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}
