import * as React from 'react'
import { useFeature } from '@toasttab/ec-features'
import { useEffect } from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

type Args = {
  newUrl: string
}

const useSimplifiedLoginRedirect = (args: Args) => {
  const { newUrl } = args

  const hasSimplifiedLogin = useFeature(
    'ec-platform-use-simplified-login-flow',
    true
  )

  const hasRedirectBackEnabled = useFeature(
    'ec-platform-revert-simplified-login-flow'
  )

  const shouldRedirect = !!(
    newUrl &&
    hasSimplifiedLogin &&
    // if we have old -> new redirect on,
    // then having this redirect on as well will cause a live-lock loop
    // redirecting the user back and forth forever
    !hasRedirectBackEnabled
  )

  const searchStr = window.location.search

  const fullUrl = newUrl + (searchStr || '')

  const redirect = () => {
    console.log('redirecting to new login flow', { fullUrl, newUrl, searchStr })

    window.location.href = fullUrl
  }

  useEffect(() => {
    if (shouldRedirect) redirect()
  }, [shouldRedirect]) // eslint-disable-line react-hooks/exhaustive-deps

  return { shouldRedirect, fullUrl }
}

type Props = {
  newUrl: string
  children: React.ReactNode
}

const SimplifiedLoginRedirectGuard = (props: Props) => {
  const { newUrl, children } = props

  const { shouldRedirect } = useSimplifiedLoginRedirect({ newUrl })

  if (shouldRedirect) {
    return (
      <div className='pin-center'>
        <MerryGoRound />
      </div>
    )
  }

  return <>{children}</>
}

export { useSimplifiedLoginRedirect, SimplifiedLoginRedirectGuard }
