import * as yup from 'yup'

export const Validation = {
  validateToken: yup.string().required('token_required'),
  validatePassword: yup.string().required('password_required'),
  validateCompanyCode: yup.string().required('companyCode_required'),
  validateEmail: yup.string().required('email_required'),
  validateCustomerUuid: yup.string().required('customerUuid_required'),
  validateUserUuid: yup.string().required('userUuid_required'),
  validateCustomerId: yup.number().required('customerId_required'),
  validateLoginNonce: yup.string().required('loginNonce_required')
}

export enum ErrorMessageType {
  COMPANY_CODE_NOT_FOUND = 'error.companyCodeNotFound',
  LEGACY_USERNAME_PROVIDED = 'error.legacyUsernameProvided',
  UNAUTHORIZED = 'error.unauthorized',
  CUSTOMER_ACCESS_NOT_ALLOWED = 'error.customerAccessNotAllowed',
  UNKNOWN = 'error.unknown',
  EXPIRED_PASSWORD = 'error.expiredPassword'
}

export enum LoginErrorType {
  PAYROLL_CUSTOMER_NOT_FOUND = 'payrollCustomerNotFound',
  LEGACY_USERNAME_PROVIDED = 'legacyUsernameProvided',
  LINKED_USER_NOT_FOUND = 'linkedUserNotFound',
  PAYROLL_USER_NOT_FOUND = 'payrollUserNotFound',
  CUSTOMER_ACCESS_NOT_ALLOWED = 'customerAccessNotAllowed'
}

export enum LoginCommandType {
  FIND_USER,
  AUTHENTICATE,
  VALIDATE_TOKEN,
  VALIDATE_GOOGLE_TOKEN,
  RESET_PASSWORD,
  SEND_TOKEN_EMAIL
}

export enum LoginStatus {
  WAITING,
  REQUESTING,
  ERROR
}

export enum CookieName {
  COMPANY_CODE = 'companyCode',
  IS_PAYROLL_LOGIN = 'loginApplication=payroll'
}

export enum LoginScreen {
  COMPANY_CODE_AND_EMAIL = '/CompanyCode',
  SECURITY_UPDATE = '/CompanyCode/security-update',
  PASSWORD = '/CompanyCode/password',
  FORGOT_PASSWORD = '/CompanyCode/forgot-password',
  RESET_PASSWORD = '/CompanyCode/reset-password',
  TOKEN = '/CompanyCode/token',
  GOOGLE_AUTHENTICATOR = '/CompanyCode/google-authenticator'
}

const isErrorMessageType =
  <T extends ErrorMessageType>(type: T) =>
  (value: unknown): value is T =>
    type === value

const makeEqualityTypeGuard =
  <T>(type: string | number): ((obj: any) => obj is T) =>
  (obj: any): obj is T =>
    obj?.type === type

export const isLegacyUsernameErrorType = isErrorMessageType(
  ErrorMessageType.LEGACY_USERNAME_PROVIDED
)

export const isExpiredPasswordErrorType = isErrorMessageType(
  ErrorMessageType.EXPIRED_PASSWORD
)

export const isCompanyCodeNotFoundErrorType = isErrorMessageType(
  ErrorMessageType.COMPANY_CODE_NOT_FOUND
)

export type LoginErrorResponse<T extends LoginErrorType> =
  T extends LoginErrorType.LEGACY_USERNAME_PROVIDED
    ? { userUuid: string; username: string }
    : T extends LoginErrorType.PAYROLL_CUSTOMER_NOT_FOUND
    ? { identifier: string }
    : T extends LoginErrorType.LINKED_USER_NOT_FOUND
    ? {}
    : T extends LoginErrorType.CUSTOMER_ACCESS_NOT_ALLOWED
    ? { companyCode: string }
    : unknown

export class FindUser {
  readonly type = LoginCommandType.FIND_USER
  static readonly is = makeEqualityTypeGuard<FindUser>(
    LoginCommandType.FIND_USER
  )

  private constructor(readonly companyCode: string, readonly email: string) {}

  static schema = yup
    .object({
      companyCode: Validation.validateCompanyCode,
      email: Validation.validateEmail
    })
    .defined()

  static copyOf(obj: FindUser): FindUser {
    return new FindUser(obj.companyCode, obj.email)
  }

  static of(obj: any): FindUser {
    return FindUser.copyOf(
      FindUser.schema.validateSync(obj, { abortEarly: false }) as FindUser
    )
  }
}

export class ResetPassword {
  readonly type = LoginCommandType.RESET_PASSWORD
  static readonly is = makeEqualityTypeGuard<ResetPassword>(
    LoginCommandType.RESET_PASSWORD
  )

  private constructor(readonly companyCode: string, readonly email: string) {}

  static schema = yup
    .object({
      companyCode: Validation.validateCompanyCode,
      email: Validation.validateEmail
    })
    .defined()

  static copyOf(obj: ResetPassword): ResetPassword {
    return new ResetPassword(obj.companyCode, obj.email)
  }

  static of(obj: any): ResetPassword {
    return ResetPassword.copyOf(
      ResetPassword.schema.validateSync(obj, {
        abortEarly: false
      }) as ResetPassword
    )
  }
}

export class LinkedPayrollUser {
  private constructor(
    readonly customerUuid: string,
    readonly userUuid: string,
    readonly customerId: number,
    readonly companyCode: string
  ) {}

  static schema = yup
    .object({
      customerUuid: Validation.validateCustomerUuid,
      userUuid: Validation.validateUserUuid,
      customerId: Validation.validateCustomerId,
      companyCode: Validation.validateCompanyCode
    })
    .defined()

  static copyOf(obj: LinkedPayrollUser): LinkedPayrollUser {
    return new LinkedPayrollUser(
      obj.customerUuid,
      obj.userUuid,
      obj.customerId,
      obj.companyCode
    )
  }

  static of(obj: any): LinkedPayrollUser {
    return LinkedPayrollUser.copyOf(
      LinkedPayrollUser.schema.validateSync(obj, { abortEarly: false })
    )
  }
}

export class SendTokenEmail {
  readonly type = LoginCommandType.SEND_TOKEN_EMAIL
  static readonly is = makeEqualityTypeGuard<SendTokenEmail>(
    LoginCommandType.SEND_TOKEN_EMAIL
  )

  private constructor(
    readonly companyCode: string,
    readonly email: string,
    readonly password: string
  ) {}

  static schema = yup
    .object({
      companyCode: Validation.validateCompanyCode,
      email: Validation.validateEmail,
      password: Validation.validatePassword
    })
    .defined()

  static copyOf(obj: SendTokenEmail): SendTokenEmail {
    return new SendTokenEmail(obj.companyCode, obj.email, obj.password)
  }

  static of(obj: any): SendTokenEmail {
    return SendTokenEmail.copyOf(
      SendTokenEmail.schema.validateSync(obj, {
        abortEarly: false
      }) as SendTokenEmail
    )
  }
}

export class Authenticate {
  readonly type = LoginCommandType.AUTHENTICATE
  static readonly is = makeEqualityTypeGuard<Authenticate>(
    LoginCommandType.AUTHENTICATE
  )

  private constructor(
    readonly companyCode: string,
    readonly email: string,
    readonly password: string
  ) {}

  static schema = yup
    .object({
      companyCode: Validation.validateCompanyCode,
      email: Validation.validateEmail,
      password: Validation.validatePassword
    })
    .defined()

  static copyOf(obj: Authenticate): Authenticate {
    return new Authenticate(obj.companyCode, obj.email, obj.password)
  }

  static of(obj: any): Authenticate {
    return Authenticate.copyOf(
      Authenticate.schema.validateSync(obj, {
        abortEarly: false
      }) as Authenticate
    )
  }
}

export class ValidateToken {
  readonly type = LoginCommandType.VALIDATE_TOKEN
  static readonly is = makeEqualityTypeGuard<ValidateToken>(
    LoginCommandType.VALIDATE_TOKEN
  )

  private constructor(
    readonly companyCode: string,
    readonly email: string,
    readonly password: string,
    readonly token: string
  ) {}

  static schema = yup
    .object({
      companyCode: Validation.validateCompanyCode,
      email: Validation.validateEmail,
      password: Validation.validatePassword,
      token: Validation.validateToken
    })
    .defined()

  static copyOf(obj: ValidateToken): ValidateToken {
    return new ValidateToken(
      obj.companyCode,
      obj.email,
      obj.password,
      obj.token
    )
  }

  static of(obj: any): ValidateToken {
    return ValidateToken.copyOf(
      ValidateToken.schema.validateSync(obj, {
        abortEarly: false
      }) as ValidateToken
    )
  }
}

export class ValidateGoogleToken {
  readonly type = LoginCommandType.VALIDATE_GOOGLE_TOKEN
  static readonly is = makeEqualityTypeGuard<ValidateGoogleToken>(
    LoginCommandType.VALIDATE_GOOGLE_TOKEN
  )

  private constructor(
    readonly companyCode: string,
    readonly email: string,
    readonly password: string,
    readonly token: string
  ) {}

  static schema = yup
    .object({
      companyCode: Validation.validateCompanyCode,
      email: Validation.validateEmail,
      password: Validation.validatePassword,
      token: Validation.validateToken
    })
    .defined()

  static copyOf(obj: ValidateGoogleToken): ValidateGoogleToken {
    return new ValidateGoogleToken(
      obj.companyCode,
      obj.email,
      obj.password,
      obj.token
    )
  }

  static of(obj: any): ValidateGoogleToken {
    return ValidateGoogleToken.copyOf(
      ValidateGoogleToken.schema.validateSync(obj, {
        abortEarly: false
      }) as ValidateGoogleToken
    )
  }
}

export class LinkedPayrollUserAndNonce {
  private constructor(
    readonly user: LinkedPayrollUser,
    readonly loginNonce: string
  ) {}

  static schema = yup
    .object({
      user: yup.mixed<LinkedPayrollUser>().required(),
      loginNonce: Validation.validateLoginNonce
    })
    .defined()

  static copyOf(obj: LinkedPayrollUserAndNonce): LinkedPayrollUserAndNonce {
    return new LinkedPayrollUserAndNonce(obj.user, obj.loginNonce)
  }

  static of(obj: any): LinkedPayrollUserAndNonce {
    return LinkedPayrollUserAndNonce.copyOf(
      LinkedPayrollUserAndNonce.schema.validateSync(obj, { abortEarly: false })
    )
  }
}

export type LoginCommand =
  | FindUser
  | Authenticate
  | ValidateToken
  | ValidateGoogleToken
  | ResetPassword
  | SendTokenEmail
