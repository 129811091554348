import * as React from 'react'
import { useFormikContext } from 'formik'

export interface ValidateFormOnChangeProps {
  value: any
}

/**
 * Runs formik validation within the context of a formik form when
 * the provided value changes. Useful for triggering formik validation
 * from state outside of the forms context.
 */
export const ValidateFormOnChange: React.FC<ValidateFormOnChangeProps> = (
  props
) => {
  const { validateForm } = useFormikContext()

  React.useEffect(() => {
    validateForm()
  }, [props.value, validateForm])

  return null
}
